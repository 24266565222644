#footer {
  height: 5em;
  width: 100%;
  position: absolute;
  background-color: color("chicago");
  left: 0;
  bottom: 0;
  color: color("white");
  overflow: hidden;

  .region {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    padding: modular-scale(1) 0;
  }

  .main-logo {
    @include size(3em);
  }

  .block-menu {
    h2 {
      font-size: modular-scale(0);
    }

    .menu {
      padding-left: modular-scale(1);
    }

    .leaf {
      list-style: none;
    }

    li {
      font-size: modular-scale(0);
    }

    a {
      color: color("white");
      text-decoration: none;
    }
  }
}
