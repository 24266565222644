.messages.error {
  padding-top: 6em;
  margin-top: 0;
  margin-bottom: -4em;
  background-color: color("chicago");
  border-color: color("chicago");
  color: color("white");
}

.messages.status {
  padding-top: 6em;
  margin-top: 0;
  margin-bottom: -4em;
  background-color: color("botticelli");
  border-color: color("botticelli");
  color: color("black");
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 0;

  @include media(">=lgdesktop-bp") {
    max-width: 1410px;
    min-width: 1200px;
  }
}

#content {
  padding-top: 6em;
}

.page-node-4 {
  h1 {
    font-size: 2em;
  }

  #content {
    padding-top: 3em;
  }
}
.region-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  h2 {
    @include media(">=desktop-bp") {
      margin-left: 1em;
    }

    @include media(">=lgdesktop-bp") {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }

  .views-form {
    form div {
      @include media(">=tablet-bp") {
        flex-wrap: wrap;
        display: flex;
        align-items: flex-start;
        width: 100%;
      }

      .views-row {
        @include media(">=tablet-bp") {
          width: 50%;
          padding: 30px 0;
        }

        h5 {
          text-transform: none;
        }

        .field-content {
          @include media(">=tablet-bp") {
            width: auto;
          }
        }

        .views-field-title {
          @include media(">=tablet-bp") {
            width: 100%;
            margin-bottom: 0;
          }

        }

        .views-field-field-book {
          @include media(">=tablet-bp") {
            width: 35%;
            float: left;
          }


          img {
            width: 90%;
            height: 90%;
            margin: 0 10% 10%;
            padding-right: .5em;
            padding-left: .5em;

            @include media(">=mobile-bp") {
              width: 50%;
              max-width: 250px;
              height: 10%;
            }

            @include media(">=tablet-bp") {
              width: 90%;
              height: 80%;
              max-height: 200px;
              max-width: 210px;
            }
          }
        }

        .views-field-nothing-1 {
          @include media(">=desktop-bp") {
            width: 65%;
          }

          .views-field-add-to-cart-quantity {
            display: flex;
            margin: .5em auto 1em;
            color: color("chicago");

            .views-label {
              padding-right: .8em;
              font-weight: bold;
            }

            .form-item-add-to-cart-quantity-0,
            .form-item-add-to-cart-quantity-1,
            .form-item-add-to-cart-quantity-2,
            .form-item-add-to-cart-quantity-3{
              width: 50%;
              margin-top: -.1em;
            }
          }
        }

        a {
          font-family: $body-font;
          color: color("alizarin-crimson");
          font-weight: bold;
        }

        h5 {
          color: color("chicago");
        }
      }
    }
  }

  .view-content {
    font-family: $body-font;
    color: color("natural-gray");

    .views-row {
      width: 95%;
      padding: 30px 0;
      z-index: 2;

      @include media(">=desktop-bp") {
        width: 45%;
        padding-bottom: 40px;

        &.views-row-2 {
          padding-left: 25px;
          padding-right: 0;
        }
      }

      &.views-row-1,
      &.views-row-2 {
        padding-right: 25px;
      }

    }

    a {
      font-family: $body-font;
    }

    h5 {
      color: color("chicago");
    }

    .views-field-nothing {
      color: color("alizarin-crimson");
      margin-bottom: 1em;
      font-weight: bold;
    }

    .price {
      color: color("alizarin-crimson");

      span {
        color: color("chicago");
        font-weight: bold;
      }
    }

    .form-item-quantity {
      display: flex;
      margin: 0 auto 1em;
      color: color("chicago");

      label {
        padding-right: .8em;
        color: color("alizarin-crimson");
        font-size: 1em;
        font-weight: bold;
      }
    }

    .form-submit {
      float: right;
    }
  }

  .views-field-add-to-cart-quantity {
    display: flex;
    margin: 0 auto 1em;
    color: color("chicago");

    .views-label {
      padding-right: .8em;
      color: color("chicago");
    }

    input {
      width: 50px;
      height: 30px;
      border: 1px solid color("alizarin-crimson");
      text-align: center;
      z-index: 2;
    }
  }

  #commerce-checkout-form-shipping,
  #commerce-checkout-form-review {
    @include media(">=tablet-bp") {
      width: 500px;
    }
    @include media(">=desktop-bp") {
      width: 700px;
    }
  }

  #commerce-checkout-form-checkout {
    width: 100%;
  }

  .customer_profile_shipping {
    width: 98%;
  }

  .customer_profile_billing,
  .form-item-commerce-coupon-coupon-code {
    input,
    select {
      width: 100%;
    }
  }

  .commerce-line-item-views-form  table.views-table {
    width: 320px;

    @include media(">=tablet-bp") {
      width: 700px;
    }

    @include media (">=desktop-bp") {
      width: 720px;
      margin-left: 1em;
    }
  }


  .webform-client-form {

    .webform-component input {
      width: 95%;

      &.form-radio {
        width: 10%;
      }
    }
  }

  #user-login, #user-pass {
    input {
      width: 95%;
    }
  }

}

.tabs {
  ul {
    &.tabs {
      &.primary {
        display: block;

        @include media(">=tablet-bp") {
          display: flex;
        }
      }
    }
  }
}

.front {
  .region-content {
    @include media(">=desktop-bp") {
      margin-top: 4em;
    }

    .price {
      display: inline;
    }
  }

  &.logged-in {
    .messages.error {
      margin-bottom: -8em;
    }

    .messages.status {
      margin-bottom: -9.5em;
    }

    .form-actions {
      @include media(">=desktop-bp") {
        top: 5%;
      }
      @include media(">=lgdesktop-bp") {
        top: 8%;
      }
    }

    .form-submit {
      margin-top: 4em;
    }

    &.admin-menu {
      .form-submit {
        margin-top: -4em;
      }
    }
  }

  .form-actions {
    @include media(">=desktop-bp") {
      @include position(absolute, 15% 0 0 0);
    }

    @include media(">=lgdesktop-bp") {
      top: 18%;
    }
  }

  .form-submit {
    width: 100%;
    height: 50px;
    margin: -2em 0 1em 65%;
    text-transform: uppercase;

    @include media(">=mobile-bp") {
      width: 80%;
      float: none !important;
      margin: -2em 2em 1em;
    }

    @include media(">=tablet-bp") {
      width: 40%;
      float: right;
      margin-left: 65%;
      margin-right: 0;
    }

    @include media(">=desktop-bp") {
      width: 20%;
    }

    @include media(">=extralgdesktop-bp") {
      width: 15%;
    }
  }
}

.view-commerce-user-orders table {
  font-size: 1em;
  color: color("black");
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .region-content {
    display: inline-block;
  }
}