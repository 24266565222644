.view-commerce-cart-form {
  width: 90%;
}
.commerce-line-item-views-form {
  width: 90%;

  .views-table {
    margin: 0 0 35px;
    font-size: 1em;

    th {
      padding: 0 10px;
      border-bottom: 1px solid color("natural-gray");
    }

    tr.even, tr.odd {
      border-top: 1px solid color("natural-gray");
      border-bottom: 1px solid color("natural-gray");
      background: none;
    }

    td {
      padding: 10px;

      .form-text {
        text-indent: 5px;
      }
    }

    .delete-line-item {
      width: 100%;
    }

    @include media(">=mobile-bp") {
      width: 700px;
    }

    @include media(">=desktop-bp") {
      width: 1024px;

      .views-table {
        width: 1024px;

        .views-field-line-item-title {
          width: 60%;
        }

        .views-field-edit-delete {
          width: 10%;

          .delete-line-item {
            width: 100%;
          }
        }

      }
    }
  }

  .line-item-summary {
    text-align: left;
    margin-left: 1em;
  }

  .commerce-line-item-actions {
    text-align: left;
    display: flex;

    .form-submit {
      margin: 0 5px;
      width: 45%;
      height: 50px;
      margin-left: 1em;
    }
  }
}

.page-checkout {
  .region-content {
    padding-bottom: 2.5%;
    font-size: 1em;

    #commerce-checkout-form-review {
      .checkout-help,
      table {
        color: color("chicago");
        font-size: 1em;
        font-family: $body-font;
      }
    }
  }
}