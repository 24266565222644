html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0 0 5em;
  overflow-x: hidden;

}
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../img/hero.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

// REMOVES THE STATEMENT ABOUT NO CONTENT
#first-time { display: none; }