.block-menu {
  margin: 5px 10px;
}

.responsive-menus {
  &.responsified {
    span.toggler {
      box-shadow: none;
      background-color: transparent !important;
    }

    .responsive-menus-simple {
      padding: 0 25px !important;
      background-color: color("alizarin-crimson");
    }
  }
}