.form-submit,
.confirm,
button {
  cursor: pointer;
  color: color("white");
  width: 80%;
  padding: 5px 0;
  background: linear-gradient(to bottom, color("scarlet") 30%, color("milano-red") 100%);
  border: 2px outset color("alizarin-crimson");
  font-size: 1.2em;
  font-family: $body-font;

  @include media(">=tablet-bp") {
    width: 45%;
  }

  @include media(">=desktop-bp") {
    width: 20%;
    font-size: 1em;
  }
}

.account-confirm {
  color: color("alizarin-crimson");

  &:hover {
    text-decoration: underline;
  }
}

.page-checkout {
  .checkout-continue {
    &.form-submit {
      height: 50px;
      font-size: 1em;

      @include media(">=desktop-bp") {
        width: 30%;
      }

      &.checkout-processed {
        @include media(">=desktop-bp") {
          width: 50%;
        }
      }
    }
  }

  .checkout-cancel {
    &.form-submit {
      width: 45%;
      height: 50px;
      margin-top: 15px;
      font-size: 1em;

      @include media(">=desktop-bp") {
        width: 15%;
        font-size: 1.5em;
      }
    }
  }

  .button-operator {
    margin: 0 15px;
  }
}