/**
*    ___   ___    _  _  ___ _____   __  __  ___  ___ ___ _____   __
*   |   \ / _ \  | \| |/ _ \_   _| |  \/  |/ _ \|   \_ _| __\ \ / /
*   | |) | (_) | | .` | (_) || |   | |\/| | (_) | |) | || _| \ V /
*   |___/ \___/  |_|\_|\___/ |_|   |_|  |_|\___/|___/___|_|   |_|
* This file is generated with SASS and will be overwritten
*/
/**
 * THEME COLORS
 */
/**
 * COLOR MAP - light and dark gray are wireframes
 */
/**
 * FONT IMPORTS AND SETTINGS - all but admin font IES specific
 */
/**
 * GLOBAL SETTINGS
 */
/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
   ========================================================================== */
/**
 * Collapse border spacing
 */
table {
  border-collapse: collapse; }

/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; }

/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * 1. Remove the default vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* ARIA (https://w3c.github.io/html-aria/)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* =Typography
----------------------------------------------- */
body, html {
  font-size: 16px;
  font-family: Arial Regular, Helvetica, sans-serif; }

a {
  text-decoration: none;
  color: inherit; }

.typography.light {
  color: get-color("black"); }
  .typography.light a {
    color: green;
    text-decoration: underline; }
  .typography.light h1, .typography.light h2, .typography.light h3, .typography.light h4, .typography.light h5, .typography.light h6 {
    color: #000; }

.typography.dark {
  color: get-color("white"); }

p, small, h1, h2, h3, h4, h5, h6, li, button, svg text {
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p, li {
  font-family: Arial Regular, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.333em; }

p:last-child {
  margin-bottom: 0; }

small {
  display: block;
  font-size: 0.64em; }

h1, h2, h3, h4, h5, h6 {
  font-family: Arial Regular, Helvetica, sans-serif; }

h1 {
  font-size: 80px;
  font-weight: 600;
  text-transform: uppercase;
  color: #5E5751;
  line-height: 0.8; }

h2 {
  font-size: 30px;
  font-weight: 300;
  color: #958D86; }
  @media (min-width: 1200px) {
    h2 {
      font-size: 45px; } }

h3 {
  font-size: 3.05176em;
  font-weight: 600; }

h4 {
  font-size: 2.44141em;
  font-weight: 600; }

h5 {
  font-size: 1.5em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: .5em; }

h6 {
  font-size: 1.5625em;
  font-weight: 600;
  text-transform: uppercase; }

button {
  font-family: Arial Regular, Helvetica, sans-serif; }

.no-margin {
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0; }

.no-line-height {
  line-height: 0; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.w-xlight {
  font-weight: 200; }

.w-light {
  font-weight: 300; }

.w-regular {
  font-weight: 400; }

.w-semibold {
  font-weight: 600; }

.w-bold, .typography.light a {
  font-weight: 700; }

.w-xbold {
  font-weight: 800; }

.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.italic {
  font-style: italic; }

.underline {
  text-decoration: underline; }

.sub-script {
  position: relative;
  top: 4px;
  font-size: 60%; }

.text-shadow {
  text-shadow: 0px 5px 12px rgba(0, 0, 0, 0.45); }

.text-shadow-alt {
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.45); }

#admin-menu {
  background: #101010 !important; }
  #admin-menu li {
    font-family: "Verdana", "Geneva", sans-serif;
    font-size: 1em; }
  #admin-menu a {
    color: #fafafa !important;
    font-weight: 600; }

ul.tabs.primary {
  display: flex;
  align-items: center; }
  ul.tabs.primary li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 1em;
    font-family: "Verdana", "Geneva", sans-serif;
    font-size: 0.8em; }
  ul.tabs.primary a {
    padding: 0.5em 2em;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: 600; }

ul.contextual-links li {
  font-family: "Verdana", "Geneva", sans-serif;
  font-size: 1em;
  font-weight: 600; }

html {
  min-height: 100%;
  position: relative; }

body {
  margin: 0 0 5em;
  overflow-x: hidden; }

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-image: url("../../img/hero.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

#first-time {
  display: none; }

.block-menu {
  margin: 5px 10px; }

.responsive-menus.responsified span.toggler {
  box-shadow: none;
  background-color: transparent !important; }

.responsive-menus.responsified .responsive-menus-simple {
  padding: 0 25px !important;
  background-color: #E2231A; }

#sliding-popup {
  width: 100%;
  padding: 2em;
  background-color: #E2231A;
  color: #ffffff; }
  #sliding-popup .popup-content #popup-text,
  #sliding-popup .popup-content #popup-buttons {
    max-width: 100%;
    float: none; }
    @media (min-width: 768px) {
      #sliding-popup .popup-content #popup-text,
      #sliding-popup .popup-content #popup-buttons {
        max-width: 80%; } }
  #sliding-popup .popup-content #popup-text p {
    margin-bottom: 0; }
  #sliding-popup .popup-content #popup-text button {
    width: 100%;
    padding: 0 1em 1.2em 0;
    background: none;
    border: none;
    text-align: left;
    font-weight: bold;
    text-decoration: underline; }
    @media (min-width: 768px) {
      #sliding-popup .popup-content #popup-text button {
        width: 55%; } }
    #sliding-popup .popup-content #popup-text button:hover {
      color: #004777; }

.pill-box {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 4em;
  border-radius: 20em;
  background: #000;
  cursor: pointer;
  transition: background 0.3s; }
  .pill-box .copy {
    color: #ffffff;
    transition: color 0.3s; }
  @media (min-width: 1024px) {
    .pill-box {
      width: 20em; } }

.no-touchevents .pill-box:hover {
  background: #E2231A; }
  .no-touchevents .pill-box:hover .copy {
    color: #000; }

.form-submit,
.confirm,
button {
  cursor: pointer;
  color: #ffffff;
  width: 80%;
  padding: 5px 0;
  background: linear-gradient(to bottom, #fa2000 30%, #cd1a04 100%);
  border: 2px outset #E2231A;
  font-size: 1.2em;
  font-family: Arial Regular, Helvetica, sans-serif; }
  @media (min-width: 768px) {
    .form-submit,
    .confirm,
    button {
      width: 45%; } }
  @media (min-width: 1024px) {
    .form-submit,
    .confirm,
    button {
      width: 20%;
      font-size: 1em; } }

.account-confirm {
  color: #E2231A; }
  .account-confirm:hover {
    text-decoration: underline; }

.page-checkout .checkout-continue.form-submit {
  height: 50px;
  font-size: 1em; }
  @media (min-width: 1024px) {
    .page-checkout .checkout-continue.form-submit {
      width: 30%; } }
  @media (min-width: 1024px) {
    .page-checkout .checkout-continue.form-submit.checkout-processed {
      width: 50%; } }

.page-checkout .checkout-cancel.form-submit {
  width: 45%;
  height: 50px;
  margin-top: 15px;
  font-size: 1em; }
  @media (min-width: 1024px) {
    .page-checkout .checkout-cancel.form-submit {
      width: 15%;
      font-size: 1.5em; } }

.page-checkout .button-operator {
  margin: 0 15px; }

.view-commerce-cart-form {
  width: 90%; }

.commerce-line-item-views-form {
  width: 90%; }
  .commerce-line-item-views-form .views-table {
    margin: 0 0 35px;
    font-size: 1em; }
    .commerce-line-item-views-form .views-table th {
      padding: 0 10px;
      border-bottom: 1px solid #958D86; }
    .commerce-line-item-views-form .views-table tr.even, .commerce-line-item-views-form .views-table tr.odd {
      border-top: 1px solid #958D86;
      border-bottom: 1px solid #958D86;
      background: none; }
    .commerce-line-item-views-form .views-table td {
      padding: 10px; }
      .commerce-line-item-views-form .views-table td .form-text {
        text-indent: 5px; }
    .commerce-line-item-views-form .views-table .delete-line-item {
      width: 100%; }
    @media (min-width: 420px) {
      .commerce-line-item-views-form .views-table {
        width: 700px; } }
    @media (min-width: 1024px) {
      .commerce-line-item-views-form .views-table {
        width: 1024px; }
        .commerce-line-item-views-form .views-table .views-table {
          width: 1024px; }
          .commerce-line-item-views-form .views-table .views-table .views-field-line-item-title {
            width: 60%; }
          .commerce-line-item-views-form .views-table .views-table .views-field-edit-delete {
            width: 10%; }
            .commerce-line-item-views-form .views-table .views-table .views-field-edit-delete .delete-line-item {
              width: 100%; } }
  .commerce-line-item-views-form .line-item-summary {
    text-align: left;
    margin-left: 1em; }
  .commerce-line-item-views-form .commerce-line-item-actions {
    text-align: left;
    display: flex; }
    .commerce-line-item-views-form .commerce-line-item-actions .form-submit {
      margin: 0 5px;
      width: 45%;
      height: 50px;
      margin-left: 1em; }

.page-checkout .region-content {
  padding-bottom: 2.5%;
  font-size: 1em; }
  .page-checkout .region-content #commerce-checkout-form-review .checkout-help,
  .page-checkout .region-content #commerce-checkout-form-review table {
    color: #5E5751;
    font-size: 1em;
    font-family: Arial Regular, Helvetica, sans-serif; }

.pdf-header {
  background-color: #E2231A; }

.pdf-footer {
  height: 100px;
  padding-top: 100px;
  background-color: #5E5751; }

.pdf-orderTotal {
  margin-bottom: 100px; }

.field-name-commerce-order-total .commerce-price-formatted-components {
  width: 33%;
  margin-left: auto; }

.field-name-commerce-order-total .commerce-price-formatted-components tr.component-type-commerce-price-formatted-amount {
  font-weight: bold; }

.products ul {
  display: none; }

tr.odd,
tr.even {
  background: none repeat scroll 0 0 #ffffff; }

.component-title {
  font-weight: bold; }

table {
  width: 100%;
  border-spacing: 0;
  font-size: 12px; }

table.details td {
  padding: 0 5px; }

table th {
  padding: 0;
  border: 0;
  background-color: #004777;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold; }

table th,
.products table td {
  padding: 4px 5px; }

.views-field-commerce-total,
.component-total {
  text-align: right; }

.line-items {
  border-bottom: 1px dotted #958D86; }

.products table.commerce-price-formatted-components {
  width: 200px;
  float: right;
  padding-top: 20px; }

#footer {
  height: 5em;
  width: 100%;
  position: absolute;
  background-color: #5E5751;
  left: 0;
  bottom: 0;
  color: #ffffff;
  overflow: hidden; }
  #footer .region {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1.25em 0; }
  #footer .main-logo {
    height: 3em;
    width: 3em; }
  #footer .block-menu h2 {
    font-size: 1em; }
  #footer .block-menu .menu {
    padding-left: 1.25em; }
  #footer .block-menu .leaf {
    list-style: none; }
  #footer .block-menu li {
    font-size: 1em; }
  #footer .block-menu a {
    color: #ffffff;
    text-decoration: none; }

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5em;
  position: fixed;
  padding: 0 2em;
  background-color: #E2231A;
  z-index: 10; }
  #header .main-logo {
    width: 124px; }
    @media (min-width: 768px) {
      #header .main-logo {
        margin-top: 10px; } }
    #header .main-logo img {
      width: 100%;
      height: auto; }
  #header .region {
    display: flex;
    align-items: center; }
  #header .region-nav {
    width: 15%;
    margin-top: 10px;
    float: right; }
    @media (min-width: 768px) {
      #header .region-nav {
        width: 80%;
        margin-top: 0; }
        #header .region-nav ul {
          padding-left: 0; } }
  #header .block-menu .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase; }
    #header .block-menu .menu .leaf {
      list-style: none; }
      @media (min-width: 1024px) {
        #header .block-menu .menu .leaf {
          padding: 0 .75em; } }
  #header .block-menu a {
    color: #ffffff;
    text-decoration: none;
    padding: 1.7em 0; }
    #header .block-menu a:hover {
      border-bottom: 3px solid #ffffff; }
  #header .block-menu li {
    font-size: 1em; }

.region-top-nowrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #958D86; }

.messages.error {
  padding-top: 6em;
  margin-top: 0;
  margin-bottom: -4em;
  background-color: #5E5751;
  border-color: #5E5751;
  color: #ffffff; }

.messages.status {
  padding-top: 6em;
  margin-top: 0;
  margin-bottom: -4em;
  background-color: #D9E1EC;
  border-color: #D9E1EC;
  color: #000; }

.container {
  width: 90%;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1410px;
      min-width: 1200px; } }

#content {
  padding-top: 6em; }

.page-node-4 h1 {
  font-size: 2em; }

.page-node-4 #content {
  padding-top: 3em; }

.region-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%; }
  @media (min-width: 1024px) {
    .region-content h2 {
      margin-left: 1em; } }
  @media (min-width: 1200px) {
    .region-content h2 {
      margin-top: 0.5em;
      margin-bottom: 1em; } }
  @media (min-width: 768px) {
    .region-content .views-form form div {
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
      width: 100%; } }
  @media (min-width: 768px) {
    .region-content .views-form form div .views-row {
      width: 50%;
      padding: 30px 0; } }
  .region-content .views-form form div .views-row h5 {
    text-transform: none; }
  @media (min-width: 768px) {
    .region-content .views-form form div .views-row .field-content {
      width: auto; } }
  @media (min-width: 768px) {
    .region-content .views-form form div .views-row .views-field-title {
      width: 100%;
      margin-bottom: 0; } }
  @media (min-width: 768px) {
    .region-content .views-form form div .views-row .views-field-field-book {
      width: 35%;
      float: left; } }
  .region-content .views-form form div .views-row .views-field-field-book img {
    width: 90%;
    height: 90%;
    margin: 0 10% 10%;
    padding-right: .5em;
    padding-left: .5em; }
    @media (min-width: 420px) {
      .region-content .views-form form div .views-row .views-field-field-book img {
        width: 50%;
        max-width: 250px;
        height: 10%; } }
    @media (min-width: 768px) {
      .region-content .views-form form div .views-row .views-field-field-book img {
        width: 90%;
        height: 80%;
        max-height: 200px;
        max-width: 210px; } }
  @media (min-width: 1024px) {
    .region-content .views-form form div .views-row .views-field-nothing-1 {
      width: 65%; } }
  .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity {
    display: flex;
    margin: .5em auto 1em;
    color: #5E5751; }
    .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity .views-label {
      padding-right: .8em;
      font-weight: bold; }
    .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity .form-item-add-to-cart-quantity-0,
    .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity .form-item-add-to-cart-quantity-1,
    .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity .form-item-add-to-cart-quantity-2,
    .region-content .views-form form div .views-row .views-field-nothing-1 .views-field-add-to-cart-quantity .form-item-add-to-cart-quantity-3 {
      width: 50%;
      margin-top: -.1em; }
  .region-content .views-form form div .views-row a {
    font-family: Arial Regular, Helvetica, sans-serif;
    color: #E2231A;
    font-weight: bold; }
  .region-content .views-form form div .views-row h5 {
    color: #5E5751; }
  .region-content .view-content {
    font-family: Arial Regular, Helvetica, sans-serif;
    color: #958D86; }
    .region-content .view-content .views-row {
      width: 95%;
      padding: 30px 0;
      z-index: 2; }
      @media (min-width: 1024px) {
        .region-content .view-content .views-row {
          width: 45%;
          padding-bottom: 40px; }
          .region-content .view-content .views-row.views-row-2 {
            padding-left: 25px;
            padding-right: 0; } }
      .region-content .view-content .views-row.views-row-1, .region-content .view-content .views-row.views-row-2 {
        padding-right: 25px; }
    .region-content .view-content a {
      font-family: Arial Regular, Helvetica, sans-serif; }
    .region-content .view-content h5 {
      color: #5E5751; }
    .region-content .view-content .views-field-nothing {
      color: #E2231A;
      margin-bottom: 1em;
      font-weight: bold; }
    .region-content .view-content .price {
      color: #E2231A; }
      .region-content .view-content .price span {
        color: #5E5751;
        font-weight: bold; }
    .region-content .view-content .form-item-quantity {
      display: flex;
      margin: 0 auto 1em;
      color: #5E5751; }
      .region-content .view-content .form-item-quantity label {
        padding-right: .8em;
        color: #E2231A;
        font-size: 1em;
        font-weight: bold; }
    .region-content .view-content .form-submit {
      float: right; }
  .region-content .views-field-add-to-cart-quantity {
    display: flex;
    margin: 0 auto 1em;
    color: #5E5751; }
    .region-content .views-field-add-to-cart-quantity .views-label {
      padding-right: .8em;
      color: #5E5751; }
    .region-content .views-field-add-to-cart-quantity input {
      width: 50px;
      height: 30px;
      border: 1px solid #E2231A;
      text-align: center;
      z-index: 2; }
  @media (min-width: 768px) {
    .region-content #commerce-checkout-form-shipping,
    .region-content #commerce-checkout-form-review {
      width: 500px; } }
  @media (min-width: 1024px) {
    .region-content #commerce-checkout-form-shipping,
    .region-content #commerce-checkout-form-review {
      width: 700px; } }
  .region-content #commerce-checkout-form-checkout {
    width: 100%; }
  .region-content .customer_profile_shipping {
    width: 98%; }
  .region-content .customer_profile_billing input,
  .region-content .customer_profile_billing select,
  .region-content .form-item-commerce-coupon-coupon-code input,
  .region-content .form-item-commerce-coupon-coupon-code select {
    width: 100%; }
  .region-content .commerce-line-item-views-form table.views-table {
    width: 320px; }
    @media (min-width: 768px) {
      .region-content .commerce-line-item-views-form table.views-table {
        width: 700px; } }
    @media (min-width: 1024px) {
      .region-content .commerce-line-item-views-form table.views-table {
        width: 720px;
        margin-left: 1em; } }
  .region-content .webform-client-form .webform-component input {
    width: 95%; }
    .region-content .webform-client-form .webform-component input.form-radio {
      width: 10%; }
  .region-content #user-login input, .region-content #user-pass input {
    width: 95%; }

.tabs ul.tabs.primary {
  display: block; }
  @media (min-width: 768px) {
    .tabs ul.tabs.primary {
      display: flex; } }

@media (min-width: 1024px) {
  .front .region-content {
    margin-top: 4em; } }

.front .region-content .price {
  display: inline; }

.front.logged-in .messages.error {
  margin-bottom: -8em; }

.front.logged-in .messages.status {
  margin-bottom: -9.5em; }

@media (min-width: 1024px) {
  .front.logged-in .form-actions {
    top: 5%; } }

@media (min-width: 1200px) {
  .front.logged-in .form-actions {
    top: 8%; } }

.front.logged-in .form-submit {
  margin-top: 4em; }

.front.logged-in.admin-menu .form-submit {
  margin-top: -4em; }

@media (min-width: 1024px) {
  .front .form-actions {
    position: absolute;
    top: 15%;
    right: 0;
    bottom: 0;
    left: 0; } }

@media (min-width: 1200px) {
  .front .form-actions {
    top: 18%; } }

.front .form-submit {
  width: 100%;
  height: 50px;
  margin: -2em 0 1em 65%;
  text-transform: uppercase; }
  @media (min-width: 420px) {
    .front .form-submit {
      width: 80%;
      float: none !important;
      margin: -2em 2em 1em; } }
  @media (min-width: 768px) {
    .front .form-submit {
      width: 40%;
      float: right;
      margin-left: 65%;
      margin-right: 0; } }
  @media (min-width: 1024px) {
    .front .form-submit {
      width: 20%; } }
  @media (min-width: 1400px) {
    .front .form-submit {
      width: 15%; } }

.view-commerce-user-orders table {
  font-size: 1em;
  color: #000; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .region-content {
    display: inline-block; } }

@media (min-width: 1024px) {
  .sidebar-right #content {
    display: flex;
    justify-content: center; } }

@media (min-width: 1024px) {
  .sidebar-right #content .container {
    width: 75%;
    max-width: 900px;
    min-width: 500px;
    margin: 0; } }

@media (min-width: 1024px) {
  .sidebar-right.node-type-webform #content .container {
    width: 50%;
    max-width: 600px;
    min-width: 400px; } }

.sidebar-right.node-type-webform #sidebar-right {
  margin-top: 0; }
  .sidebar-right.node-type-webform #sidebar-right h2 {
    font-size: 30px; }

.sidebar-right.node-type-webform .region-content {
  width: 95%; }

.sidebar-right .region-content {
  width: 70%; }

.sidebar-right #sidebar-right {
  width: 95%;
  margin: 1em auto; }
  @media (min-width: 1024px) {
    .sidebar-right #sidebar-right {
      width: 25%;
      margin: -3em 0 1em 0; } }
  .sidebar-right #sidebar-right h2 {
    margin-bottom: 0; }
  .sidebar-right #sidebar-right p {
    color: #5E5751; }

.region-bottom-nowrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
