#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5em;
  position: fixed;
  padding: 0 2em;
  background-color: color("alizarin-crimson");
  z-index: 10;

  .main-logo {
    width: 124px;

    @include media(">=tablet-bp") {
      margin-top: 10px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .region {
    display: flex;
    align-items: center;
  }

  .region-nav {
    width: 15%;
    margin-top: 10px;
    float: right;

    @include media(">=tablet-bp") {
      width: 80%;
      margin-top: 0;

      ul {
        padding-left: 0;
      }
    }
  }

  .block-menu {
    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-transform: uppercase;

      .leaf {
        list-style: none;

        @include media(">=desktop-bp") {
          padding: 0 .75em;
        }
      }
    }

    a {
      color: color("white");
      text-decoration: none;
      padding: 1.7em 0;

      &:hover {
        border-bottom: 3px solid color("white");
      }
    }

    li {
      font-size: modular-scale(0);
    }
  }
}
