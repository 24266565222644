.sidebar-right {
  #content {
    @include media(">=desktop-bp") {
      display: flex;
      justify-content: center;
    }

    .container {
      @include media(">=desktop-bp") {
        width: 75%;
        max-width: 900px;
        min-width: 500px;
        margin: 0;
      }
    }
  }

  &.node-type-webform {
    #content {
      .container {
        @include media(">=desktop-bp") {
          width: 50%;
          max-width: 600px;
          min-width: 400px;
        }

      }
    }

    #sidebar-right {
      margin-top: 0;

      h2 {
        font-size: 30px;
      }
    }

    .region-content {
      width: 95%;
    }
  }

  .region-content {
    width: 70%;
  }

  #sidebar-right {
    width: 95%;
    margin: 1em auto;

    @include media(">=desktop-bp") {
      width: 25%;
      margin: -3em 0 1em 0;
    }

    h2 {
      margin-bottom: 0;
    }

    p {
      color: color("chicago");
    }
  }
}