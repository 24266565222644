.pdf-header {
  background-color: color("alizarin-crimson");
}

.pdf-footer {
  height: 100px;
  padding-top: 100px;
  background-color: color("chicago");
}

.pdf-orderTotal {
  margin-bottom: 100px;
}

.field-name-commerce-order-total .commerce-price-formatted-components {
  width: 33%;
  margin-left: auto;
}

.field-name-commerce-order-total .commerce-price-formatted-components tr.component-type-commerce-price-formatted-amount {
  font-weight: bold;
}

.products ul{
  display:none;
}

tr.odd,
tr.even {
  background: none repeat scroll 0 0 color("white");
}

.component-title {
  font-weight: bold;
}

table {
  width:100%;
  border-spacing: 0;
  font-size: 12px;
}

table.details td {
  padding: 0 5px;
}

table th {
  padding: 0;
  border: 0;
  background-color: color("regal-blue");
  color: color("white");
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

table th,
.products table td {
  padding: 4px 5px;
}

.views-field-commerce-total,
.component-total {
  text-align: right;
}

.line-items {
  border-bottom: 1px dotted color("natural-gray");
}

.products table.commerce-price-formatted-components {
  width: 200px;
  float: right;
  padding-top: 20px;
}