/* =Typography
----------------------------------------------- */
// typography - SPECIFIC TO IES
//@import url('https://fonts.googleapis.com/css?family=Lato');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300');

// body font
body, html {
  font-size: 16px;
  font-family: $body-font;
}
// links
a {
  text-decoration: none;
  color: inherit;
}

// color definitions
.typography.light {
  color: get-color("black");

  a {
    @extend .w-bold;
    color: green;
    text-decoration: underline;
  }

  a:hover {
    //color: get-color(black);
  }

  h1, h2, h3, h4, h5, h6 {
    color: color("black");
  }
}

.typography.dark {
  color: get-color("white");

  a {
    //color: get-color(white);
  }

  a:hover {
    //color: get-color(white);
  }

  h1, h2, h3, h4, h5, h6 {
    //color: get-color(white);
  }
}

// font styling and sizing
p, small, h1, h2, h3, h4, h5, h6, li, button, svg text {
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, li  {
  font-family: $body-font;
  font-size: 1em;
  line-height: 1.333em;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

small {
  display: block;
  font-size: modular-scale(-2);
}

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font;
}

h1 {
  font-size: 80px;
  font-weight: 600;
  text-transform: uppercase;
  color: color("chicago");
  line-height: 0.8;
}

h2 {
  font-size: 30px;
  font-weight: 300;
  color: color("natural-gray");

  @include media(">=lgdesktop-bp") {
    font-size: 45px;
  }
}

h3 {
  font-size: modular-scale(5);
  font-weight: 600;
}

h4 {
  font-size: modular-scale(4);
  font-weight: 600;
}

h5 {
  font-size: 1.5em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: .5em;
}

h6 {
  font-size: modular-scale(2);
  font-weight: 600;
  text-transform: uppercase;
}

button {
  font-family: $button-font;
}

.no-margin {
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 0;
}

.no-line-height {
  line-height: 0;
}

// positional helper classes
.text-center { text-align: center; }
.text-left   { text-align: left; }
.text-right  { text-align: right; }
.text-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// text styling helper classes
.w-xlight    { font-weight: 200; }
.w-light     { font-weight: 300; }
.w-regular   { font-weight: 400; }
.w-semibold  { font-weight: 600; }
.w-bold      { font-weight: 700; }
.w-xbold     { font-weight: 800; }
.uppercase   { text-transform: uppercase; }
.lowercase   { text-transform: lowercase; }
.capitalize  { text-transform: capitalize; }
.italic      { font-style: italic; }
.underline   { text-decoration: underline; }

// super / sub script
.sub-script {
  position: relative;
  top: 4px;
  font-size: 60%;
}

// shadows
.text-shadow {
  text-shadow: 0px 5px 12px rgba(0, 0, 0, 0.45);
}

.text-shadow-alt {
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.45);
}
