#sliding-popup {
  width: 100%;
  padding: 2em;
  background-color: color("alizarin-crimson");
  color: color("white");

  .popup-content {
    #popup-text,
    #popup-buttons {
      max-width: 100%;
      float: none;

      @include media(">=tablet-bp") {
        max-width: 80%;
      }
    }

    #popup-text {
      p {
        margin-bottom: 0;
      }

      button {
        width: 100%;
        padding: 0 1em 1.2em 0;
        background: none;
        border: none;
        text-align: left;
        font-weight: bold;
        text-decoration: underline;

        @include media(">=tablet-bp") {
          width: 55%;
        }

        &:hover {
          color: color("regal-blue");
        }
      }
    }
  }
}